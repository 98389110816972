<template>
	<Header></Header>
	<div class="loginBox" :style="{'margin-top':marginStyle,'margin-bottom':marginStyle}">
		<div class="title">找回密码</div>
		<div class="login">
			<el-form
				:model="ruleForm"
				:rules="rules"
				ref="ruleForm"
				label-position="top"
				class="ruleForm"
			>
				<el-form-item label="手机号" prop="ceMobile">
				  <el-input v-model="ruleForm.ceMobile" placeholder="请输入手机号"></el-input>
				</el-form-item>
				<el-form-item label="验证码" prop="code">
				  <div style="display: flex;">
				    <div style="flex:1;width:344px;">
				      <el-input v-model="ruleForm.code" autocomplete="off" placeholder="请输入手机验证码"></el-input>
				    </div>
				    <div style="margin-left:4px;">
				      <el-button type="primary" :disabled="disableSend" @click="slideBtn">{{sendText}}</el-button>
				    </div>
				  </div>
				</el-form-item>
				<el-form-item label="密码" prop="urPassword">
				  <el-input type="password" v-model="ruleForm.urPassword" placeholder="请输入密码"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="comPassword">
				  <el-input type="password" v-model="ruleForm.comPassword" placeholder="请输入确认密码"></el-input>
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" @click="submitForm('ruleForm')" round style="width:100%" :loading="loading">确认</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
	<Footer></Footer>
	<el-dialog
		width="500px"
		top="30vh"
		title="请按住滑块，拖动到最右边"
		v-model="dialogVisible"
	    :before-close="handleClose"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:destroy-on-close="true"
		custom-class="dialog"
	>
		<div id="nc"></div>
	</el-dialog>
</template>

<script>
	import Header from "../components/Header"
	import Footer from "../components/Footer"
	export default {
		components:{Header,Footer},
		data(){
			const validateMobile = (rule,value, callback) => {
				if(!(/^1[345789]\d{9}$/.test(value))){ 
					callback(new Error('请输入正确的手机号码'))
				} else {
					callback()
				}
			}
			const validatePass = (rule,value, callback) => {
				if(!(/((?=.*[a-z])(?=.*\d)|(?=[a-z])(?=.*[#@!~%^&*])|(?=.*\d)(?=.*[#@!~%^&*]))[a-z\d#@!~%^&*]{8,16}/i.test(value))){ 
					callback(new Error('密码格式为字母、数字和字符串组合'))
				} else {
					callback()
				}
			}
			const validateComPass = (rule,value, callback) => {
				let urPassword = this.ruleForm.urPassword;
				if(urPassword != value){
					callback(new Error('两次密码输入不一致'))
				}else{
					callback()
				}
			}
			return{
				marginStyle:'',
				loading:false,
				dialogVisible:false,
				ruleForm:{
					ceMobile: '',
					urPassword: '',
					comPassword:'',
					code:'',
					type:1
				},
				rules: {
					ceMobile:[
						{ required: true, validator: validateMobile, trigger: 'change' }
					],
					urPassword: [
						{ required: true, validator: validatePass, trigger: 'change' }
					],
					comPassword: [
						{ required: true, validator: validateComPass, trigger: 'change' }
					],
					code: [
						{ required: true, message: '验证码不能为空', trigger: 'change' }
					]
				},
				disableSend:false,
				sendText:'发送验证码',
				sendTime: 120,
				timer: null,
				loading:false
			}
		},
		methods:{
			submitForm(formName){
				let that = this;
				that.$refs[formName].validate((valid) => {
					if (valid) {
						let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
						if(!reg.test(that.ruleForm.ceMobile)){ 
							that.$message.error('请输入正确的手机号码');
							return false;
						}
						if(that.ruleForm.urPassword != that.ruleForm.comPassword){
							that.$message.error('两次密码输入不一致！');
							return false;
						}
						that.ruleForm.urMobile = that.ruleForm.ceMobile;
						that.loading = true;
						this.$axios({
							method:'post',
							url:'user/ur/initPass',
							data:this.$qs.stringify(that.ruleForm)
						})
						.then((res)=>{
							let msg = res.data.msg;
							if(msg==2){
								that.$router.push({ name:'Login'});
							}else{
								that.$message.error(msg);
							}
							that.loading = false;
						})
						.catch((err)=>console.log('请求失败',err))
					} else {
						return false;
					}
				});
			},
			slideBtn(){
				let that = this;
				let reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
				if(!reg.test(that.ruleForm.ceMobile)){ 
					that.$message.error('请输入正确的手机号码');
					return false;
				} 
				that.dialogVisible = true;
				that.$nextTick(()=>{
					AWSC.use("nc", function (state, module) {
						window.nc = module.init({
							appkey: "FFFF0N0000000000AA0F",
							scene: "nc_message",
							renderTo: "nc",
							width:450,
							success: function (data) {
								that.sendSMS(data.sessionId,data.sig,data.token);
								that.dialogVisible = false;
							},
							fail: function (failCode) {
								 window.console && console.log(failCode)
								 window.nc.reset();
							},
							error: function (errorCode) {
								window.nc.reset();
								 window.console && console.log(errorCode)
							}
						});
					})
				})
			},
			sendSMS(sessionId,sig,token){
				let that =this;
				this.$axios({
					method:'post',
					url:'sys/ce/sendFindPass',
					data:this.$qs.stringify({
						sessionId: sessionId,
						sig: sig,
						token: token,
						ceMobile: that.ruleForm.ceMobile
					})
				})
				.then((res)=>{
					let msg = res.data.msg;
					if(msg==2){
						that.$message.success("验证码发送成功，120秒内有效，请注意查收！");
						that.startCounter();
					}else{
						window.nc.reset();
						that.$message.error(msg);
					}
				})
				.catch((err)=>console.log('请求失败',err))
			},
			startCounter(){
				let that = this;
				that.disableSend = true;
				that.timer = setInterval(()=> {
					that.sendTime --;
					that.sendText = this.sendTime+"秒后可重新发送";
					if(that.sendTime <= 0 ) {
						that.disableSend = false;
						clearInterval(that.timer);
						that.sendTime = 120;
						that.sendText = "发送验证码";
					}
				},1000);
			},
			setHeight(){
				let h = document.documentElement.clientHeight || document.body.clientHeight;
				this.marginStyle = (h - 677)/2 + 'px';
			}
		},
		beforeUnmount() {
			try{
				clearInterval(this.timer);
			}catch(e){}
		},
		beforeMount(){
		   this.setHeight();
		},
		mounted(){
		   let that = this;
		   window.addEventListener('resize',that.setHeight(),false);
		},
		beforeDestroy(){
		   window.removeEventListener('resize',this.setHeight(),false)
		}
	}
</script>
<style scoped>
	.loginBox{
		width:450px;
		height:440px;
		margin:0px auto;
	}
	.title{
		font-weight: 600;
		font-size: 24px;
		color: rgb(4, 17, 29);
		margin-bottom:15px;
	}
	.loginBox .el-button{
		height:40px;
		border-radius: 10px;
	}
	#nc_1__scale_text{
		background: #409eff !important;
	  /* 默认背景色 */
	}
	
	.nc_scale div.nc_bg {
		background: #409eff !important;
	  /* 滑过时的背景色 */
	}
	
	.nc_scale .scale_text2 {
		color: #fff !important;
	  /* 滑过时的字体颜色 */
	}
	
	.nc_scale span {
		border: 1px solid #ccc !important;
	}
	
	.errloading {
	  border: #faf1d5 1px solid !important;
	  color: #ef9f06 !important;
	}
</style>
